<template>
    <div>
        <!-- <c-table
          :url="`ReportWebService/get_customers?contractedUserEmail=${email}` "
          :per-page="10"
          :fields="columns"
          :columns="[
            'firstname',
            'lastname',
            'register',
            'phone',
            'email',
            'loan_max_amount',
            'created_date',
            'contracted_date',
            'employerName',
            'employerDistrict',
            'emergency_number',
            'contracted_number'
          ]"
          :filename="'Хэрэглэгч'"
          :filters="filters"
          orderDirection="DESC"
          orderBy="created_date"
          hover
          bordered
          fixed
          caption=""
        >
        </c-table> -->
        <b-card>
          <div class="mb-3">
              <b-form @submit.stop.prevent="submitSearch">
                <b-input-group>
                  <b-input-group-prepend is-text
                    ><i class="fa fa-search"></i
                  ></b-input-group-prepend>
                  <b-form-input
                    v-model="search"
                    placeholder="Хайх"
                  ></b-form-input>
                  <b-input-group-append
                    ><b-button variant="primary" type="submit"
                      >Хайх</b-button
                    ></b-input-group-append
                  >
                  <b-input-group-append
                    ><b-button variant="secondary"
                      ><i class="fa fa-refresh"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
             </div>

          <div class="table-responsive mb-0"
              v-if="items != undefined && items.length > 0">


                <b-table
                  stacked="md"
                  hover
                  bordered
                  small
                  responsive="sm"
                  :items="items"
                  :fields="columns"
                  no-local-sorting
                >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div>
                      <b-button-group>
                        <b-button
                          size="sm"
                          variant="outline-primary"
                          :to="{
                            name: 'admin.customer.detail',
                            params: { id: data.item.id }
                          }"
                            
                          v-if="checkPermission('admin.customer.detail')"
                          class="mr-1"
                          v-b-tooltip.hover
                          title="Хэрэглэгч"
                        >
                          <i class="fa fa-id-card"></i>
                        </b-button>
                      </b-button-group>
                    </div>
                  </template>

                  <!-- <template v-slot:cell(amountDebit)="data">
                    <span v-if="data.item.amount > 0">{{ data.item.amount | number }}</span>
                  </template> -->

                  <!-- <template v-slot:cell(email2)="data">
                    <b-button variant="primary" size="sm" @click="showModal(data.item.email)">Хэрэглэгчид</b-button>
                  </template> -->
                </b-table>

              <div slot="footer" class="mb-0 mt-3">
              <nav class="pull-right" v-if="pagination.total > query.per_page"><b-pagination class="mb-0" :total-rows="pagination.total" :per-page="query.per_page" v-model="query.page"/></nav>
              <p class="mb-0">{{ pagination.total }} өгөгдлөөс {{ (pagination.to)?pagination.from:0 }} - {{ pagination.to }} өгөгдөл харагдаж байна.</p>
            </div>  
          </div>

          <b-alert variant="warning" v-else show class="mb-0"
      >Мэдээлэл байхгүй байна</b-alert>
        </b-card>
     
    </div>
</template>
<script>
import moment from "moment";
export default {
    name: "ReportContractUsers",
    props: ["email"],
    data: function() {
        return {
            columns: [
                { label: "№", key: "index", class: "text-center" },
                {
                label: "Овог",
                key: "lastname",
                sortable: true,
                sort_key: "lastname",
                },
                {
                label: "Нэр",
                key: "firstname",
                sortable: true,
                sort_key: "firstname",
                },
                {
                label: "Регистр",
                key: "register",
                sortable: true,
                sort_key: "register",
                },
                { label: "Утас", key: "phone", sortable: true, sort_key: "phone" },
                // { label: 'И-мэйл', key: 'email'},
                {
                label: "Зээлийн лимит",
                key: "maxAmount",
                sortable: true,
                sort_key: "loan_max_amount",
                class: "text-right",
                },
                // {
                // label: "НД",
                // key: "isPaidNd",
                // sortable: true,
                // sort_key: "is_contract",
                // class: "text-center",
                // },
                // {
                // label: "Гэрээ",
                // key: "isContract",
                // sortable: true,
                // sort_key: "is_contract",
                // class: "text-center",
                // },
                // {
                // label: "Гэрээ байгуулсан ажилтан",
                // key: "contractedDtl",
                // class: "text-center",
                // },
                // { label: "Идэвхтэй", key: "isActive", class: "text-center" },
                // {
                // label: "Зээлийн эрх",
                // key: "isIncomeInfo",
                // sortable: true,
                // sort_key: "is_contract",
                // class: "text-center",
                // },
                {
                label: "Гэрээ байгуулсан огноо",
                key: "contractedDate",
                sortable: true,
                sort_key: "contracted_date",
                },
                // {
                // label: "Төлбөр хийх өдөр",
                // key: "repaymentDay",
                // sortable: true,
                // sort_key: "repayment_day",
                // },
                // {
                // label: "Үүсгэсэн огноо",
                // key: "createdDate",
                // sortable: true,
                // sort_key: "created_date",
                // }
            ],  
            query: {
              id: 0,
              page: 1,
              per_page: 10,
              startAt: '',
              endAt: '',
              search: '',
              contractedUserEmail: '',

            },
            pagination: {
              total: 0,
              from: 0,
              to: 0
            },
            search: "",
            items: [],
        }
    },

    created: function() {
      this.loadData();
      var _buttons = [];
      if (this.checkPermission("admin.customer.detail")) {
      _buttons.push({
        icon: "fa fa-id-card",
        label: "",
        variant: "outline-primary",
        title: "Хэрэглэгчийн дэлгэрэнгүй",
        route: "admin.customer.detail",
        route_param: "id",
      });
    }
    if (_buttons.length > 0) {
      this.$data.columns.push({
        label: "Үйлдэл",
        key: "actions",
        buttons: _buttons,
        class: "text-center",
      });
    }
    },
    watch: {
      query: {
        handler (query) {
          this.loadData();
        },
        deep: true
      },
    },
    methods: {
        loadData: async function() {
            console.log('hell2o')
            this.checkSession();
            this.isLoading = true;
            this.$data.query.contractedUserEmail = this.email

            this.$http.get(this.$config.API_URL + `ReportWebService/get_customers?columns=firstname,lastname,register,phone`, {
                params: this.$data.query,
                headers: this.$store.getters.httpHeader,
                emulateJSON: true,
            }).then(
                (response) => {
                    console.log(response)
                    this.$data.items = response.body.data
                    this.$data.pagination.total = response.body.total
                    this.$data.pagination.from = response.body.from
                    this.$data.pagination.to = response.body.to

              }
            )
        },
        submitSearch: function() {
          console.log('hello')
          this.$data.query.search = this.$data.search;
        },
    }
}
</script>
